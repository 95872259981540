import React from 'react'
import cx from 'classnames'
import dayjs from 'dayjs'

import { Box, Link, Text } from '@piconetworks/ui'
import styles from './LoginFooter.module.scss'
import addTestId from 'utils/lib/addTestId'

const LoginFooter = () => {
    return (
        <React.Fragment>
            <Box.Flexbox.Container flexDirection="row" justifyContent="between">
                <Box.Flexbox.Child alignSelf="start">
                    <Box.Flexbox.Container justifyContent="between" className={cx(styles.footerLinksWrapper)}>
                        <Link className={cx(styles.link)} target="_blank" href="https://hype.co/about">
                            <Text.Tiny>About</Text.Tiny>
                        </Link>
                        <Link className={cx(styles.link)} target="_blank" href="https://help.hype.co/">
                            <Text.Tiny>Help</Text.Tiny>
                        </Link>
                        <Link className={cx(styles.link)} target="_blank" href="https://hype.co/terms">
                            <Text.Tiny>Privacy & Terms</Text.Tiny>
                        </Link>
                    </Box.Flexbox.Container>
                </Box.Flexbox.Child>

                <Box.Flexbox.Child alignSelf="end">
                    <Box.Flexbox.Container justifyContent="between" className={cx(styles.footerLinksWrapper)}>
                        <Link className={cx(styles.link)} href="https://www.instagram.com/hype.card" target="_blank">
                            <span className="icon-icon_social_instagram" />
                        </Link>
                        <Link className={cx(styles.link)} href="https://www.tiktok.com/@hype.card" target="_blank">
                            <span className="icon-icon_social_tiktok">
                                <span className="path1" />
                                <span className="path2" />
                                <span className="path3" />
                            </span>
                        </Link>
                        <Link className={cx(styles.link)} href="https://www.youtube.com/@hype_kit" target="_blank">
                            <span className="icon-icon_social_youtube" />
                        </Link>
                        <Link className={cx(styles.link)} href="https://twitter.com/hype.kit" target="_blank">
                            <span className="icon-icon_social_twitter" />
                        </Link>
                    </Box.Flexbox.Container>
                </Box.Flexbox.Child>
            </Box.Flexbox.Container>
            <Box.Flexbox.Container alignItems="center" flexWrap="wrap">
                <Text.P className={styles.copyright} data-testid={addTestId('copyright-year')}>
                    <Text.Tiny>©{dayjs().format('YYYY')} Hype® All rights reserved.</Text.Tiny>
                </Text.P>
            </Box.Flexbox.Container>
        </React.Fragment>
    )
}

export default LoginFooter

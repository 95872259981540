import React, { useContext } from 'react'
import { Box } from '@piconetworks/ui'
import LoginFooter from '../LoginFooter'
import styles from './LoginLayout.module.scss'
import Picon from '@piconetworks/pkg-picon'
import ScreenSizeContext from 'utils/contexts/ScreenSize/context'

const LoginLayout = ({ children }) => {
    const { isLgUp } = useContext(ScreenSizeContext)

    return (
        <Box.Flexbox.Container flexDirection="row" flexWrap="wrap" className={styles.fullHeight}>
            <Box.Flexbox.Child flex={'1'}>
                <Box.Flexbox.Container className={styles.loginLeftPanel} justifyContent="center" alignItems="center" flexDirection="column">
                    <Picon icon="hype-brandmark" color="white" size={isLgUp ? "250": "100"} />
                </Box.Flexbox.Container>
            </Box.Flexbox.Child>
            <Box.Flexbox.Child flex={'auto'}>
                <Box.Flexbox.Container className={styles.loginRightPanel} alignItems="center" justifyContent="between" flexDirection="column">
                    <Box.Flexbox.Child className={styles.loginRightPanelInner}>
                        {children}
                    </Box.Flexbox.Child>
                    <Box.Flexbox.Child className={styles.loginFooter}>
                        <LoginFooter />
                    </Box.Flexbox.Child>
                </Box.Flexbox.Container>
            </Box.Flexbox.Child>
        </Box.Flexbox.Container>
    )
}

export default LoginLayout

import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import Link from 'next/link'
import cx from 'classnames'
import { Form as ReactFinalForm, Field } from 'react-final-form'

import { PICO_API_URL } from '@piconetworks/pkg-endpoints'
import { Boutton, Box, SocialLoginButton, Text, Spacing } from '@piconetworks/ui'
import Form from '@piconetworks/pkg-form'
import LoginLayout from './LoginLayout'
import styles from './Login.module.scss'

import AuthModule from 'modules/auth'

const getGoogleIcon = () => (
    <span className={cx(styles.googleIcon, 'icon-icon_social_google')}>
        <span className="path1" />
        <span className="path2" />
        <span className="path3" />
        <span className="path4" />
    </span>
)

const getAlertIcon = () => (
    <span className="icon-icon_issue">
        <span className="path1" />
        <span className="path2" />
    </span>
)

const Login = ({ dispatch, isError }) => {

    return (
        <LoginLayout>
            <ReactFinalForm
                onSubmit={(values) => dispatch(AuthModule.creators.login({ payload: values }))}
                render={({ handleSubmit, submitting, form }) => {
                    return (
                        <form onSubmit={handleSubmit} className={cx(styles.loginForm)}>
                            <Box.Flexbox.Container alignItems="center" flexDirection="column">
                                <Box.Flexbox.Child className={cx(styles.loginContainer)}>
                                    <SocialLoginButton
                                        onLogin={(val) => dispatch(AuthModule.creators.socialLogin(val))}
                                        onFailure={(error) => dispatch(AuthModule.creators.socialLoginError(error))}
                                        type="google"
                                        scope="publisher"
                                        button={
                                            <Boutton block variant="google" icon={getGoogleIcon()}>
                                                Log in with Google
                                            </Boutton>
                                        }
                                        api_url={PICO_API_URL()}
                                        params={{ dashboard: true }}
                                    />
                                    <Spacing.HorizontalDivider className={styles.divider} withLine>OR</Spacing.HorizontalDivider>
                                    {isError && (
                                        <Form.Feedback className={styles.error} variant='danger' icon={getAlertIcon()}>
                                            {isError.endsWith('401') 
                                                ? 'The email or password you entered was incorrect.' 
                                                : isError === 'Publisher not found' 
                                                    ? 'User not found' 
                                                    : 'An error has occurred.'
                                            }
                                        </Form.Feedback>
                                    )}
                                    <Field
                                        component={Form.Input}
                                        name="email"
                                        type="email"
                                        label="Email"
                                        placeholder="john_doe@email.com"
                                    />
                                    <Field component={Form.Input} name="password" type="password" label="Password" />
                                    <Box.Flexbox.Container alignItems="stretch" justifyContent="between">
                                        <Field
                                            component={Form.Checkbox}
                                            name="remember_me"
                                            text="Remember Me"
                                            type="checkbox"
                                        />
                                        <Text.Small>
                                            <Link href="/login/reset">Forgot Password</Link>
                                        </Text.Small>
                                    </Box.Flexbox.Container>
                                    <Boutton type="submit" variant="primary" disabled={submitting} block>
                                        Log in
                                    </Boutton>
                                    <Box.Flexbox.Container flexDirection="column" alignItems="center">
                                        <Box.Flexbox.Child>
                                            <Text.Small>Don&apos;t have a Hype account?</Text.Small>
                                        </Box.Flexbox.Child>
                                        <Box.Flexbox.Child>
                                            <Text.Small>
                                                <Link href={process.env.HYPE_ONBOARDING_URL + '/signup'}>Create an account.</Link>
                                            </Text.Small>
                                        </Box.Flexbox.Child>
                                    </Box.Flexbox.Container>
                                </Box.Flexbox.Child>
                            </Box.Flexbox.Container>
                        </form>
                    )
                }}
            />
        </LoginLayout>
    )
}

Login.propTypes = {
    dispatch: PropTypes.func,
    isError: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
}

const mapStateToProps = (state) => ({
    isError: AuthModule.selectors.getErrorStatus(state)
})

export default connect(mapStateToProps)(Login)

import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import Router from 'next/router'

import Login from 'containers/Login'
import AuthModule from 'modules/auth'

class LoginPage extends Component {
    componentDidUpdate() {
        const { isAuthed } = this.props

        if (isAuthed) {
            Router.push('/')
        }
    }

    render() {
        return <Login isError={this.props.isError} />
    }
}

LoginPage.propTypes = {
    isAuthed: PropTypes.bool,
    isError: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
}

const mapStateToProps = (state) => ({
    isAuthed: AuthModule.selectors.getAuthStatus(state),
    isError: AuthModule.selectors.getErrorStatus(state)
})

export default connect(mapStateToProps)(LoginPage)
